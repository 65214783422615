<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import { getStoresContext } from "~/stores";

  export let request_store_key;

  const stores_context = getStoresContext(request_store_key);
  const { T, reverse_router } = stores_context;

  let isLunchtime: boolean = false;
  let interval: NodeJS.Timeout;
  
  onMount(() => {
    checkLunchtime();
    resetInterval();
  });

  onDestroy(() => {
    clearInterval(interval);
  });
  
  function resetInterval() {
    clearInterval(interval);
    interval = setInterval(() => {
      checkLunchtime();
    }, 60000); //Check the time every minute
  }

  function checkLunchtime() {
    const d = new Date();
    isLunchtime = d.getHours() >= 8 && d.getHours() < 16; //Lunch time between 8am to 4pm
  }

  $: t = $T;
</script>

{#if isLunchtime}
  <a href={$reverse_router.routePurpose("lunch")}>
    <div class="lunch-list-lift">
      <p>{t`lunch.lunch-today`}</p>
      <img class="right-arrow" src="/theme/ainoa2024/images/arrow-down.png" alt="">
    </div>
  </a>
{/if}

<style lang="scss">
  .lunch-list-lift {
    background-color: var(--color-brand-secondary);
    width: 228px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--color-brand-primary-darken-1);
    transform: matrix(0, 1, -1, 0, 0, 0);
    bottom: 220px;
    right: -90px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
    z-index: 2;
    padding: 0 10px 5px 0;
    clip-path: polygon(100% 0, 88% 100%, 0 100%, 0 0);
    .right-arrow {
      margin-left: 10px;
      transform: rotate(180deg);
      filter: invert(0.8);
      width: 12px;
    }
    &:hover {
      background-color: var(--color-accent-4);
    }
  }

  @media screen and (max-width: 768px) {
    .lunch-list-lift {
      width: 205px;
      height: 42px;
      font-size: 13px;
      right: -82px;
      .right-arrow {
        width: 9px;
      }
    }
  }
</style>